<template>
    <div>
        <!-- 导航菜单 - 组件 -->
        <div class="Pc_ipad">
            <NavMenu></NavMenu>
        </div>

        <div class="phone">
            <Phone_Nav></Phone_Nav>
        </div>

        <!-- 内容展示 -->
        <!-- 70px/120px -->
        <div class="conent" :style="{ paddingTop: top ? '140px' : '140px' }">
            <router-view />
        </div>
    </div>
</template>

<script>
import NavMenu from '@/components/NavMenu.vue'
import Phone_Nav from '@/components/Phone_Nav.vue'
export default {
    data() {
        return {
            top: false
        }
    },
    components: {
        NavMenu,
        Phone_Nav
    },
    methods: {
        handleScroll() {
            //或者使用document.querySelector('.class或者#id').scrollTop
            let scroll =
                document.documentElement.scrollTop || document.body.scrollTop
            if (scroll) {
                this.top = true
            } else {
                this.top = false
            }
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },
    //销毁,否则跳到别的路由还是会出现
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    }
}
</script>

<style lang="less" scoped>
div {
    .phone {
        display: none;
    }
    .conent {
        width: 100%;
        height: auto;
        padding-bottom: 10vh;
        background-color: #fcfbfb;
    }
}

// 手机
@media screen and (max-width: 768px) {
    .Pc_ipad {
        display: none;
    }
    .phone {
        display: block !important;
    }
}
</style>