<template>
    <div
        class="Phone"
        :style="{
            height: isshow ? '144px' : '60px',
            color: isFixed ? '#ffffff' : '#999999',
            backgroundColor: isFixed ? '#D94D5D' : '#ffffff'
        }"
    >
        <div class="more">
            <div class="image" @click="onshow">
                <img
                    :src="
                        isFixed
                            ? require('@/assets/more_w.png')
                            : require('@/assets/more_r.png')
                    "
                />
            </div>
        </div>
        <!-- 隐藏/展开 84-28*3 -->
        <div class="show" :style="{ display: isshow ? 'block' : 'none' }">
            <div class="out_box" v-show="leright">
                <div>
                    <span
                        class="label"
                        @click="moveToRight"
                        :style="{
                            color:
                                activeItemId === 1
                                    ? isFixed
                                        ? '#a73b47'
                                        : '#d94d5d'
                                    : isFixed
                                    ? '#fff'
                                    : '#999999'
                        }"
                    >
                        主頁 &gt;
                    </span>
                </div>
                <div>
                    <router-link to="/change">
                        <span
                            class="label"
                            @click="setActiveItem(2)"
                            :style="{
                                color:
                                    activeItemId === 2
                                        ? isFixed
                                            ? '#a73b47'
                                            : '#d94d5d'
                                        : isFixed
                                        ? '#fff'
                                        : '#999999'
                            }"
                        >
                            找換TIPS
                        </span>
                    </router-link>
                </div>
                <div>
                    <router-link to="/contact">
                        <span
                            class="label"
                            @click="setActiveItem(3)"
                            :style="{
                                color:
                                    activeItemId === 3
                                        ? isFixed
                                            ? '#a73b47'
                                            : '#d94d5d'
                                        : isFixed
                                        ? '#fff'
                                        : '#999999'
                            }"
                        >
                            聯絡我們
                        </span>
                    </router-link>
                </div>
            </div>
            <div class="right_box" v-show="!leright">
                <div>
                    <span
                        class="label"
                        @click="moveToLeft"
                        :style="{
                            color:
                                activeItemId === 1
                                    ? isFixed
                                        ? '#a73b47'
                                        : '#d94d5d'
                                    : isFixed
                                    ? '#fff'
                                    : '#999999'
                        }"
                    >
                        &lt; back
                    </span>
                </div>
                <div>
                    <router-link to="/">
                        <span
                            class="label"
                            @click="setActiveItem(4)"
                            :style="{
                                color:
                                    activeItemId === 4
                                        ? isFixed
                                            ? '#a73b47'
                                            : '#d94d5d'
                                        : isFixed
                                        ? '#fff'
                                        : '#999999'
                            }"
                        >
                            主頁
                        </span>
                    </router-link>
                </div>
                <div>
                    <router-link to="/exchange">
                        <span
                            class="label"
                            @click="setActiveItem(5)"
                            :style="{
                                color:
                                    activeItemId === 5
                                        ? isFixed
                                            ? '#a73b47'
                                            : '#d94d5d'
                                        : isFixed
                                        ? '#fff'
                                        : '#999999'
                            }"
                        >
                            最新匯率
                        </span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            navItems: [
                { id: 1, label: '主页', path: '/' },
                { id: 2, label: '找换TIPS', path: '/change' },
                { id: 3, label: '联系我们', path: '/contact' }
            ],
            activeItemId: 1, //默认首页
            scroll: '', //滑动窗口高度
            isFixed: false,
            isshow: false,
            leright: true //左右窗口
        }
    },
    methods: {
        moveToRight() {
            this.leright = false
        },
        moveToLeft() {
            this.leright = true
        },
        onshow() {
            this.isshow = !this.isshow
            console.log(this.isshow)
        },
        setActiveItem(itemId) {
            this.activeItemId = itemId
        },
        handleScroll() {
            //或者使用document.querySelector('.class或者#id').scrollTop
            this.scroll =
                document.documentElement.scrollTop || document.body.scrollTop
            if (this.scroll) {
                this.isFixed = true
            } else {
                this.isFixed = false
            }
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },
    //销毁,否则跳到别的路由还是会出现
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    }
}
</script>

<style lang="less" scoped>
.Phone {
    width: 100%;
    height: 60px; //144px
    border-bottom: 1px solid #efeeee;
    box-shadow: 0 2px 2px #efeeee;
    position: fixed;
    top: 0;
    z-index: 100;
    transition: background-color 0.4s ease-in-out;
    .more {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        .image {
            width: 30px;
            height: auto;
            margin-left: 5vw;
            img {
                width: 100%;
            }
        }
    }
    .show {
        width: 100%;
        height: 84px;
        font-weight: 600;
        font-size: 14px;

        a {
            text-decoration: none;
        }
        .router-link-active {
            text-decoration: none;
        }
        .out_box {
            width: 100%;
            height: 100%;
            > div {
                width: 100%;
                height: 28px;
                text-align: center;
            }
        }
        .right_box {
            width: 100%;
            height: 100%;
            > div {
                width: 100%;
                height: 28px;
                text-align: center;
            }
        }
    }
}
</style>