<template>
    <div :class="{ fixed: isFixed }">
        <span v-for="item in navItems" :key="item.id">
            <router-link :to="item.path">
                <span
                    class="label"
                    @click="setActiveItem(item.id)"
                    :style="{
                        color:
                            activeItemId === item.id
                                ? isFixed
                                    ? '#a73b47'
                                    : '#d94d5d'
                                : isFixed
                                ? '#fff'
                                : '#999999'
                    }"
                >
                    {{ item.label }}
                </span>
            </router-link>
            <span :class="'show' + item.id">
                <router-link to="/exchange">
                    <span class="huilv">最新匯率</span>
                </router-link>
            </span>
        </span>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            navItems: [
                { id: 1, label: '主頁', path: '/' },
                { id: 2, label: '找換TIPS', path: '/change' },
                { id: 3, label: '聯絡我們', path: '/contact' }
            ],
            activeItemId: 1, //默认首页
            scroll: '', //滑动窗口高度
            isFixed: false
        }
    },
    created() {},
    methods: {
        setActiveItem(itemId) {
            this.activeItemId = itemId
        },
        handleScroll() {
            //或者使用document.querySelector('.class或者#id').scrollTop
            this.scroll =
                document.documentElement.scrollTop || document.body.scrollTop
            if (this.scroll) {
                this.isFixed = true
            } else {
                this.isFixed = false
            }
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },
    //销毁,否则跳到别的路由还是会出现
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    }
}
</script>

<style lang="less" scoped>
div {
    width: 100%;
    height: 120px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: end;
    transition: height 0.4s ease-in-out, background-color 0.4s ease-in-out;
    position: fixed;
    top: 0;
    z-index: 100;
    > span {
        > span {
            position: absolute;
            bottom: -34px;
            left: 0;
            width: 200px;
            height: 34px;
            background-color: #b43841;
            color: #fff;
            font-size: 12px;
            line-height: 34px;
            padding-left: 2vh;
            display: none;
            .huilv {
                color: #fff;
            }
        }
        a {
            text-decoration: none;
        }
        .router-link-active {
            text-decoration: none;
        }
        position: relative;
        padding-left: 2.2vh;
        padding-right: 2.2vh;
        cursor: pointer;
        .label {
            font-family: 'Poly' !important;
            font-style: normal !important;
            text-transform: none !important;
            letter-spacing: 1px !important;
            font-weight: 600;
            &:hover {
                color: #d94d5d !important;
            }
        }
    }
    > span:first-child {
        &:hover {
            .show1 {
                display: block;
                &:hover {
                    display: block;
                    background-color: #e6636d;
                }
            }
        }
    }
    > span:last-child {
        margin-right: 6vh;
    }
}
.fixed {
    height: 70px;
    background: #d94d5d;
    color: #fff !important;
    transition: height 0.4s ease-in-out, background-color 0.4s ease-in-out;
    .label {
        &:hover {
            color: #a73b47 !important;
        }
    }
}
// 平板
@media screen and (min-width: 768px) and(max-width: 1024px) {
    div {
        align-items: end;
        justify-content: center;
        > span {
            margin-bottom: 3vh;
        }
    }
}
</style>